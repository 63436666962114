import React, { useContext, useEffect, useState } from "react";
import api from "../api";
import { AppContext } from "../context/AppContext";
import Chart from "react-google-charts";

function TradeHistory() {
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [history, setHistory] = useState([]);
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const { data } = await api.get("/trade-history", {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setHistory(data);
      } catch (error) {
        console.error("Error fetching trade history:", error);
      }
    };
    fetchHistory();
  }, [userInfo]);
  const chartData = [
    ["Date", "Profit/Loss"],
    ...history.map((trade) => [new Date(trade.date), trade.profitLoss]),
  ];
  return (
    <div>
      <h2>Trade History</h2>
      <Chart
        chartType="LineChart"
        data={chartData}
        options={{ title: "Profit/Loss Over Time", curveType: "function" }}
        width="100%"
        height="400px"
      />
    </div>
  );
}

export default TradeHistory;
