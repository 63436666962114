import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Table, Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const TransactionList = () => {
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  console.log(transactions);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const { data } = await axios.get("/api/transactions", {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setTransactions(data);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching transactions");
      }
    };

    fetchTransactions();
  }, [userInfo]);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container>
      <h1 className="my-4">All Transactions</h1>
      {transactions.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>User</th>
              {/* <th>Investment</th> */}
              <th>Amount</th>
              <th>Payment Method</th>
              <th>Payment Status</th>
              <th>Date</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction._id}>
                <td>{transaction.transactionId}</td>
                <td>
                  {transaction.user.username} ({transaction.user.email})
                </td>
                {/* <td>{transaction.investment.name || ""}</td> */}
                <td>{transaction.amount} Rwf</td>
                <td>{transaction.paymentMethod}</td>
                <td>{transaction.paymentStatus}</td>
                <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                <td>
                  <Button
                    onClick={() => navigate(`/transaction/${transaction._id}`)}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">No transactions found</Alert>
      )}
    </Container>
  );
};

export default TransactionList;
