import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Table, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const Invest = () => {
  const [investments, setInvestments] = useState([]);
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const { data } = await axios.get(
          "/api/investments",

          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setInvestments(data);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching investments");
      }
    };

    fetchInvestments();
  }, [userInfo]);

  const subscribeToInvestment = async (investmentId) => {
    console.log(investmentId);
    try {
      await axios.post(
        `/api/investments/subscribe/${investmentId}`,
        {},
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setSuccess("Subscribed successfully");
      navigate("/portfolio"); // Redirect to portfolio or another page
    } catch (err) {
      setError(
        err.response?.data?.message || "Error subscribing to investment"
      );
    }
  };

  return (
    <Container>
      <h1 className="my-4">Investments</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      {investments.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>Risk Level</th>
              <th>Interest Per Month (%)</th>
              <th>Initial Amount ($)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {investments.map((investment) => (
              <tr key={investment._id}>
                <td>{investment.type}</td>
                <td>{investment.description}</td>
                <td>{investment.riskLevel}</td>
                <td>{investment.InterestPenMonth}</td>
                <td>{investment.InitialAmount}</td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => subscribeToInvestment(investment._id)}
                  >
                    Subscribe
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">No investments available</Alert>
      )}
    </Container>
  );
};

export default Invest;
