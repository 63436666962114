import React from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import stock from "../photo/stockM.jpeg";
import { FaUserPlus } from "react-icons/fa";
import { RiHandCoinFill } from "react-icons/ri";
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";

const HomeScreen = () => {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      style={{ backgroundColor: "rgb(3, 3, 20)", height: "auto" }}
    >
      <Row>
        <Col md={6} style={{ color: "white" }}>
          <Row
            className="h-100 shadow-sm rotate-card"
            style={{ marginLeft: "2rem", marginTop: "2rem" }}
          >
            <header className="intro-title">WELCOME TO AML</header>
            <h1>We Offer Unparalled Asset Managment Services</h1>
            <p>
              {" "}
              AML Asset Managment Ltd is a leading provider of solutions for
              <br />
              interested in alligning their financial resources with their{" "}
              <br />
              financial goals
            </p>
            <div>
              <h4>Investiment Options:120+</h4>
              <h4>Clients: 10,000+</h4>
            </div>
            <Button
              className="mt-3 mb-5"
              style={{
                borderRadius: "2rem",
                backgroundColor: "rgb(12, 65, 114)",
              }}
              sm="sm"
              onClick={() => navigate("/login")}
            >
              Get Started
            </Button>
          </Row>
        </Col>
        <Col md={6} className="mb-4">
          <Card
            className="h-100 shadow-sm rotate-card"
            style={{ margin: "2rem" }}
          >
            <Card.Img
              variant="top"
              src={stock}
              alt="Technology Deployment"
              className="rotate-img"
            />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          md={6}
          style={{ color: "rgb(3, 3, 20)", backgroundColor: "white" }}
        >
          <Card
            className="h-100 shadow-sm rotate-card"
            style={{ margin: "2rem" }}
          >
            <Card.Body>
              <RiHandCoinFill style={{ fontSize: "120px" }} />
              <h3>Investiments</h3>
              <h1>1,320</h1>
            </Card.Body>
          </Card>
        </Col>
        <Col
          md={6}
          style={{ color: "rgb(3, 3, 20)", backgroundColor: "white" }}
        >
          <Card
            className="h-100 shadow-sm rotate-card"
            style={{ margin: "2rem" }}
          >
            <Card.Body>
              <FaUserPlus style={{ fontSize: "120px" }} />
              <h3>Clients</h3>
              <h1>320</h1>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Row className="mt-5">
        <Col md={6} className="mb-4">
          <div className="auto ">
            <Card.Body>
              <div style={{ display: "flex" }}>
                <div>
                  <Card.Img variant="top" src={shake1} alt="business" />
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <AiOutlineRise style={{ fontSize: "50px" }} />
                    <h1 style={{ marginLeft: "1.5rem" }}>Start Investiment</h1>
                  </div>
                  <p>
                    We adopt a holistic approach to asset managment.
                    <br />
                    Our Team of seasonaned professionals combines
                    <br />
                    Expertise with cutting-age technology to make
                    <br />
                    informed investiment decicision
                  </p>
                </div>
                <div></div>
              </div>
            </Card.Body>
          </div>
        </Col>
      </Row> */}
    </Container>
  );
};

export default HomeScreen;
