import "./App.css";
import React, { useContext, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBars } from "react-icons/fa";
import Home from "./components/Home";
import Register from "./components/Register";
import Login from "./components/Login";
import Profile from "./components/Profile";
import AddGoal from "./components/AddGoal";
import Goals from "./components/Goals";
import AddInvestment from "./components/AddInvestment";
import Portfolio from "./components/Portfolio";
import CreateTransaction from "./components/CreateTransaction";
import Dashboard from "./components/Dashboard";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./context/AppContext";
import TransactionDetails from "./components/TransactionDetails";
import TransactionList from "./components/TransactionsList";
import Invest from "./components/Invest";
import SubscribedInvestments from "./components/SubscribedInvestments";
import PortfolioChart from "./components/PortfolioChart";
import MarketData from "./components/MarketData";
import TradeHistory from "./components/TradeHistory";

const App = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const { state } = useContext(AppContext);
  const { userInfo } = state;

  return (
    <div className="app">
      <BrowserRouter>
        <div className="App">
          <header>
            <Navbar bg="dark" variant="dark" expand="lg">
              <Container>
                <Button
                  variant="light"
                  onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
                >
                  <FaBars />
                </Button>
                <LinkContainer to="/">
                  <Navbar.Brand>Investment App</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto w-100 justify-content-end">
                    {userInfo ? (
                      <NavDropdown title={userInfo.username}>
                        <LinkContainer style={{ color: "black" }} to="/profile">
                          <Nav.Link>Profile</Nav.Link>
                        </LinkContainer>
                        <LinkContainer style={{ color: "black" }} to="/goals">
                          <Nav.Link>Goals</Nav.Link>
                        </LinkContainer>
                        <LinkContainer style={{ color: "black" }} to="/logout">
                          <Nav.Link>Logout</Nav.Link>
                        </LinkContainer>
                      </NavDropdown>
                    ) : (
                      <LinkContainer to="/login">
                        <Nav.Link>Login</Nav.Link>
                      </LinkContainer>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>
          {userInfo && (
            <div
              className={
                sidebarIsOpen ? "active-nav side-navbar" : "side-navbar"
              }
            >
              <Nav className="flex-column text-white w-100 p-2">
                <Nav.Item>
                  <LinkContainer to="/dashboard">
                    <Nav.Link>Dashboard</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to="/transactions">
                    <Nav.Link>Transactions</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to="/Invest">
                    <Nav.Link>Invest</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to="/subscribe">
                    <Nav.Link>My Investments</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to="/withdraw">
                    <Nav.Link>Withdraw</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to="/settings">
                    <Nav.Link>Settings</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              </Nav>
            </div>
          )}
          <main
            className={
              sidebarIsOpen ? "site-container active-cont" : "site-container"
            }
          >
            <ToastContainer position="bottom-center" limit={1} />
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/add-goal" element={<AddGoal />} />
              <Route path="/transaction/:id" element={<TransactionDetails />} />
              <Route path="/transactions" element={<TransactionList />} />
              <Route path="/goals" element={<Goals />} />
              <Route path="/add-investment" element={<AddInvestment />} />
              <Route path="/invest" element={<Invest />} />
              <Route path="/subscribe" element={<SubscribedInvestments />} />
              <Route path="/add-transaction" element={<CreateTransaction />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolioCharts" element={<PortfolioChart />} />
              <Route path="/marketData" element={<MarketData />} />
              <Route path="/tradeHistory" element={<TradeHistory />} />
            </Routes>
          </main>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
