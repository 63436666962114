// src/components/AddInvestment.js
import React, { useState, useContext } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const AddInvestment = () => {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(AppContext);
  const { userInfo } = state;
  const [type, setType] = useState("");
  const [InitialAmount, setInitialAmount] = useState(0);
  const [InterestPenMonth, setInterestPenMonth] = useState(0);
  const [description, setDescription] = useState("");
  const [riskLevel, setRiskLevel] = useState("");
  const [error, setError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    const investmentData = {
      type,
      description,
      riskLevel,
      InitialAmount,
      InterestPenMonth,
    };

    try {
      const res = await axios.post("/api/investments", investmentData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "SET_INVESTMENTS", payload: res.data });
      navigate("/investments");
    } catch (err) {
      setError(err.response.data.msg);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Add Investment</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">Select type</option>
            <option value="Stock">Stock</option>
            <option value="Bond">Bond</option>
            <option value="Mutual Fund">Mutual Fund</option>
            <option value="ETF">ETF</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Other">Other</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Risk Level</Form.Label>
          <Form.Control
            as="select"
            value={riskLevel}
            onChange={(e) => setRiskLevel(e.target.value)}
          >
            <option value="">Select risk level</option>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Interest Per month (%)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Interest per month"
            value={InterestPenMonth}
            onChange={(e) => setInterestPenMonth(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            value={InitialAmount}
            onChange={(e) => setInitialAmount(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Investment
        </Button>
      </Form>
    </Container>
  );
};

export default AddInvestment;
