import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Card,
  Button,
} from "react-bootstrap";
import { Chart } from "react-google-charts";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const GoalProgress = () => {
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [goals, setGoals] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchGoalProgress = async () => {
      try {
        const response = await axios.get("/api/goals/track-progress", {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }); // Update the endpoint accordingly
        console.log(response.data);

        setGoals(response.data);
      } catch (err) {
        console.error("Error fetching goals:", err);
      }
    };

    fetchGoalProgress();
  }, [userInfo]);

  const chartData = goals.map((goal) => [
    goal.name,
    goal.currentAmount,
    goal.targetAmount - goal.currentAmount,
  ]);

  const chartOptions = {
    title: "Goal Progress",
    is3D: true,
    slices: [{ offset: 0.1 }],
  };

  return (
    <Container>
      <div className="mb-4">
        <div style={{ display: "flex" }}>
          <div>
            <h2>My Goals Progress</h2>
          </div>
          <Button onClick={() => navigate("/add-goal")}>Add a new goal</Button>
        </div>
      </div>
      {goals.length > 0 ? (
        <div>
          {goals.map((goal, index) => (
            <div md={6} lg={4} key={index} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>{goal.name}</Card.Title>
                  <Card.Text>Target Amount: {goal.targetAmount} Rwf</Card.Text>
                  <Card.Text>
                    Current Amount: {goal.currentAmount} Rwf
                  </Card.Text>
                  <Card.Text>
                    Total Investments: {goal.totalInvestments} Rwf
                  </Card.Text>
                  <Card.Text>
                    Remaining Amount:{goal.remainingAmount} Rwf
                  </Card.Text>
                  <ProgressBar
                    now={goal.progressPercentage}
                    label={`${goal.progressPercentage.toFixed(2)}%`}
                  />
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <Row>
          <Col>
            <p>No goals found.</p>
          </Col>
        </Row>
      )}
      {goals.length > 0 && (
        <Row>
          <Col>
            <Chart
              chartType="PieChart"
              data={[["Goal", "Amount"], ...chartData]}
              options={chartOptions}
              width={"100%"}
              height={"400px"}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default GoalProgress;
