// src/components/CreateTransaction.js
import React, { useState, useContext } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const CreateTransaction = () => {
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [investmentId, setInvestmentId] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("MTN MoMo");

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleTransaction = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/transactions",
        {
          userId: userInfo._id,
          investmentId,
          amount,
          paymentMethod,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setMessage(response.data.msg);
      setError("");
    } catch (err) {
      setMessage("");
      setError(err.response.data.msg);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Create Transaction</h1>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleTransaction}>
        <Form.Group>
          <Form.Label>Investment ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter investment ID"
            value={investmentId}
            onChange={(e) => setInvestmentId(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Payment Method</Form.Label>
          <Form.Control
            as="select"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="VISA">VISA</option>
            <option value="PayPal">PayPal</option>
            <option value="MTN MoMo">MTN MoMo</option>
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit" disabled={!paymentMethod}>
          Create Transaction
        </Button>
      </Form>
    </Container>
  );
};

export default CreateTransaction;
