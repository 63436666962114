// src/components/Profile.js
import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { userInfo, profile } = state;
  const [fullName, setFullName] = useState(profile.fullName || "");
  const [age, setAge] = useState(profile.age || "");
  const [income, setIncome] = useState(profile.income || "");
  const [savings, setSavings] = useState(profile.savings || "");
  const [debt, setDebt] = useState(profile.debt || "");
  const [investmentExperience, setInvestmentExperience] = useState(
    profile.investmentExperience || "None"
  );
  const [riskTolerance, setRiskTolerance] = useState(
    profile.riskTolerance || "Low"
  );
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await axios.get("/api/profile", {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        dispatch({ type: "SET_PROFILE", payload: res.data });
        const {
          fullName,
          age,
          income,
          savings,
          debt,
          investmentExperience,
          riskTolerance,
        } = res.data;
        setFullName(fullName);
        setAge(age);
        setIncome(income);
        setSavings(savings);
        setDebt(debt);
        setInvestmentExperience(investmentExperience);
        setRiskTolerance(riskTolerance);
      } catch (err) {
        console.log(err);
        setError(err.response?.data?.msg || "An error occurred");
      }
    };

    if (userInfo) {
      fetchProfile();
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const profileData = {
      fullName,
      age,
      income,
      savings,
      debt,
      investmentExperience,
      riskTolerance,
    };

    try {
      const res = await axios.post("/api/profile", profileData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "SET_PROFILE", payload: res.data });
      navigate("/add-goal");
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.msg || "An error occurred");
    }
  };

  return (
    <Container className="mt-5">
      <h1>Profile</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter full name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Age</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Income</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter income"
                value={income}
                onChange={(e) => setIncome(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Savings</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter savings"
                value={savings}
                onChange={(e) => setSavings(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Debt</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter debt"
                value={debt}
                onChange={(e) => setDebt(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Investment Experience</Form.Label>
              <Form.Control
                as="select"
                value={investmentExperience}
                onChange={(e) => setInvestmentExperience(e.target.value)}
              >
                <option value="None">None</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Risk Tolerance</Form.Label>
          <Form.Control
            as="select"
            value={riskTolerance}
            onChange={(e) => setRiskTolerance(e.target.value)}
          >
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit">
          Save Profile
        </Button>
      </Form>
    </Container>
  );
};

export default Profile;
