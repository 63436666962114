import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Card, ListGroup, Alert } from "react-bootstrap";
import { AppContext } from "../context/AppContext";

const TransactionDetails = () => {
  const { id } = useParams();
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const { data } = await axios.get(`/api/transactions/${id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setTransaction(data);
        console.log(data);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching transaction");
      }
    };

    fetchTransaction();
  }, [id, userInfo]);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container className="mt-3">
      {transaction ? (
        <Card>
          <Card.Header>Transaction Details</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>Transaction ID:</strong> {transaction._id}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Amount:</strong> ${transaction.amount}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Payment Method:</strong> {transaction.paymentMethod}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Payment Status:</strong> {transaction.paymentStatus}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Date:</strong>{" "}
              {new Date(transaction.createdAt).toLocaleString()}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      ) : (
        <Alert variant="info">Loading transaction details...</Alert>
      )}
    </Container>
  );
};

export default TransactionDetails;
