// src/components/Goals.js
import React, { useContext, useEffect, useState } from "react";
import { Container, ListGroup, Alert } from "react-bootstrap";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const Goals = () => {
  const { state } = useContext(AppContext);
  const [goals, setGoals] = useState([]);
  const { userInfo } = state;
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const res = await axios.get("/api/goals", {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setGoals(res.data);
      } catch (err) {
        setError(err.response.data.msg);
      }
    };

    fetchGoals();
  }, [userInfo]);

  return (
    <Container className="mt-5">
      <h1>Your Goals</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <ListGroup>
        {goals.map((goal) => (
          <ListGroup.Item key={goal._id}>
            <h5>{goal.name}</h5>
            <p>{goal.currentAmount}</p>
            <p>{goal.riskLevel}</p>
            <p>Amount: ${goal.targetAmount}</p>
            <p>Duration: {goal.timeline} months</p>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default Goals;
