// src/context/AppContext.js
import React, { createContext, useReducer } from "react";

// Create context
export const AppContext = createContext();
const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
  profile: {
    profile: localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile"))
      : {},
    goals: [],
    investments: [],
    portfolio: [],
    loading: true,
    error: null,
  },
};
// src/context/AppReducer.js
const reducer = (action, state) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        userInfo: action.payload,
        loading: false,
      };
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case "SET_GOALS":
      return {
        ...state,
        goals: action.payload,
        loading: false,
      };
    case "SET_INVESTMENTS":
      return {
        ...state,
        investments: action.payload,
        loading: false,
      };
    case "SET_PORTFOLIO":
      return {
        ...state,
        portfolio: action.payload,
        loading: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

// Provider component
export const AppProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};
