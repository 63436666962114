import React, { useEffect, useState } from "react";
import socket from "../socket";

function MarketData() {
  const [marketData, setMarketData] = useState(null);

  useEffect(() => {
    socket.on("marketData", (data) => {
      setMarketData(data);
    });
    return () => socket.off("marketData");
  }, []);
  return (
    <div>
      <h2>Real-Time Market Data</h2>
      {marketData ? (
        <div>
          <p>Instrument: {marketData.instrument}</p>
          <p>Price: ${marketData.price.toFixed(2)}</p>
          <p>Volume: {marketData.volume.toFixed(2)}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default MarketData;
