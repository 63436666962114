import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import api from "../api";
import { AppContext } from "../context/AppContext";

function PortfolioChart() {
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get("/api/portfolio/shares", {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setPortfolio(data);
      } catch (error) {
        console.error("Error fetching portfolio:", error);
      }
    };
    fetchData();
  }, [userInfo]);
  const chartData = [
    ["Instrument", "Holdings"],
    ...portfolio.map((item) => [item.instrument.symbol, item.quantity]),
  ];
  return (
    <div>
      <h2>Portfolio</h2>
      <Chart
        chartType="PieChart"
        data={chartData}
        options={{ title: "Portfolio Holdings" }}
        width="100%"
        height="400px"
      />
    </div>
  );
}

export default PortfolioChart;
