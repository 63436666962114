import React, { useContext, useEffect, useState } from "react";
import { Container, Table, Alert } from "react-bootstrap";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const Portfolio = () => {
  const { state } = useContext(AppContext);
  const { userInfo } = state;
  const [portfolio, setPortfolio] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const res = await axios.get(`/api/portfolio`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setPortfolio(res.data);
      } catch (err) {
        setError(err.response?.data?.message || "Error fetching portfolio");
      }
    };

    fetchPortfolio();
  }, [userInfo]);

  if (!portfolio) return null;
  console.log(portfolio);

  return (
    <Container className="mt-5">
      <h1>Your Portfolio</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Investment Type</th>
            <th>Amount</th>
            <th>Duration (months)</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {portfolio.investments.map((investment) => (
            <tr key={investment._id}>
              <td>{investment.type}</td>
              <td>${investment.amount}</td>
              <td>{investment.duration}</td>
              <td>{investment.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Portfolio;
