import React from "react";
import { Card, Container } from "react-bootstrap";
import { FaWallet } from "react-icons/fa6";
import { GiProfit } from "react-icons/gi";
import GoalProgress from "./GoalProgress";
function Dashboard() {
  return (
    <Container
      style={{
        height: "87vh",
        margin: "2rem",
        display: "flex",
      }}
      className="bg-light"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 6,
        }}
      >
        <Card style={{ marginTop: "2rem" }}>
          <Card.Body>
            <div style={{ display: "flex" }}>
              <FaWallet
                style={{
                  marginTop: "1rem",
                }}
                size={40}
                color="brown"
              />
              <div>
                <p>Total Balance</p>
                <h4>2300 Rwf</h4>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: "2rem", marginLeft: "8rem" }}>
          <Card.Body>
            <div style={{ display: "flex" }}>
              <FaWallet
                style={{
                  marginTop: "1rem",
                  background: "white",
                }}
                size={40}
                color="brown"
              />
              <div>
                <p>Total Investiment</p>
                <h4>1200 Rwf</h4>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: "2rem", marginLeft: "8rem" }}>
          <Card.Body>
            <div style={{ display: "flex" }}>
              <GiProfit
                style={{
                  marginTop: "1rem",
                  background: "green",
                }}
                size={40}
                color="brown"
              />
              <div>
                <p>Profit</p>
                <h4>1200 Rwf</h4>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div
        style={{
          flex: 3,
        }}
      >
        <ul>
          <li>List</li>
          <li>List</li>
          <li>List</li>
        </ul>
      </div>
      <div>
        <GoalProgress />
      </div>
    </Container>
  );
}

export default Dashboard;
