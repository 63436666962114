// src/components/AddGoal.js
import React, { useState, useContext } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const AddGoal = () => {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(AppContext);
  const { userInfo } = state;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [currentAmount, setCurrentAmount] = useState("");
  const [riskLevel, setRiskLevel] = useState("");
  const [amount, setAmount] = useState("");
  const [duration, setDuration] = useState(new Date());
  const [error, setError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    const goalData = {
      name: title,
      currentAmount: currentAmount,
      description,
      targetAmount: amount,
      timeline: duration,
      riskLevel: riskLevel,
    };

    try {
      const response = await axios.post("/api/goals", goalData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "SET_GOALS", payload: response.data });
      navigate("/goals");
    } catch (err) {
      console.error(err); // Log the error for debugging purposes
      const errorMessage =
        err.response && err.response.data && err.response.data.msg
          ? err.response.data.msg
          : "An error occurred";
      setError(errorMessage);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Add Goal</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Current Amout</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter current amount"
            value={currentAmount}
            onChange={(e) => setCurrentAmount(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Risk level</Form.Label>
          <Form.Control
            as="select"
            value={riskLevel}
            onChange={(e) => setRiskLevel(e.target.value)}
          >
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Duration</Form.Label>
          <DatePicker
            selected={duration}
            onChange={(date) => setDuration(date)}
            dateFormat="yyyy/MM/dd"
            className="form-control"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Add Goal
        </Button>
      </Form>
    </Container>
  );
};

export default AddGoal;
